import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../logo-light.svg';

export default function Footer() {
    return (
        <footer id="footer">
            <div className="footer-top">
                {/* .container */}
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            {/* footer widget */}
                            <div className="f-widget-title">
                                <Link to="/">
                                    <img src={Logo} className="mb-4" alt="Logo" style={{ height: 50 }} />
                                </Link>
                            </div>
                            <div className="footer-text">
                                <p>
                                    Best of Real Estate deals from Jammu and Kashmir, Parts of India and Dubai, UAE. Lets guide you to the best.
                                </p>
                            </div>
                            <div className="icon-round-white footer-social mt-25">
                                <a href="http://facebook.com/peerzadaestates" title="Facebook" target="blank">
                                    <i className="fab fa-facebook" />
                                </a>
                                <a href="http://twitter.com/peerzadaestates" title="Twitter" target="blank">
                                    <i className="fab fa-twitter" />
                                </a>
                                <a href="http://instagram.com/peerzadaestates" title="Instagram" target="blank">
                                    <i className="fab fa-instagram" />
                                </a>
                                <a href="https://www.youtube.com/channel/UCDGdwYtoKYxgmkB1qbhGQBQ" title="YouTube" target="blank">
                                    <i className="fab fa-youtube" />
                                </a>
                            </div>
                        </div>{/* footer widget */}
                        <div className="col-xl-2 offset-xl-1 col-lg-2 col-sm-6">
                            {/* footer widget */}
                            <div className="f-widget-title">
                                <h4>Navigation</h4>
                            </div>
                            <div className="f-widget-link">
                                <ul>
                                    {/* Menu Link */}
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/Latest Deals">Latest Deals</Link>
                                    </li>
                                    <li>
                                        <Link to="/Services">Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/Contact Us">Contact Us</Link>
                                    </li>

                                </ul>
                            </div>
                        </div>{/* footer widget */}
                        <div className="col-xl-2 offset-xl-1 col-lg-3 col-sm-6">
                            {/* footer widget */}
                            <div className="f-widget-title">
                                <h4>Useful Links</h4>
                            </div>
                            <div className="f-widget-link">
                                <ul>
                                    {/* Menu Link */}
                                    <li>
                                        <a href="https://emicalculator.net/" target="blank">
                                            Mortgage Calculator
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://goidirectory.nic.in/" target="blank">
                                            Web Directory India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.investindia.gov.in/" target="blank">
                                            Invest India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://dolr.gov.in/" target="blank">
                                            J&K Government
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>{/* footer widget */}
                        <div className="col-xl-3 col-lg-3 col-sm-6">
                            {/* footer widget */}
                            <div className="f-widget-title">
                                <h4>Contact us</h4>
                            </div>
                            <div className="sigle-address">
                                <div className="address-icon">
                                    <i className="fas fa-home" />
                                </div>
                                <p>
                                    JAK Bro Building, Munawar Abad, Srinagar, J&K, 190001.
                                </p>
                            </div>
                            <div className="sigle-address">
                                <div className="address-icon">
                                    <i className="far fa-envelope-open" />
                                </div>
                                <p>peerzadaestates@gmail.com</p>
                            </div>
                            <div className="sigle-address">
                                <div className="address-icon">
                                    <i className="fas fa-headphones" />
                                </div>
                                <p>
                                    (+91) 908 600 0009
                                </p>
                            </div>
                        </div>{/* footer widget */}
                    </div>
                    {/* to top */}
                    <div className="cd-top"><i className="fas fa-level-up-alt" /></div>
                </div>
            </div>
            {/* .container end */}
            {/* #footer bottom start */}
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="copyright">
                            <p>
                                © {new Date().getFullYear()}
                                <a href="https://codemites.com" title="Codemites, Srinagar" target="blank"> Codemites</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="copyright text-right">
                            <p>
                                <Link to="/">Home</Link>
                                <Link to="/about">About</Link>
                                <Link to="/services">Services</Link>
                                <Link to="/deals">Deals & Projects</Link>
                            </p>
                        </div>
                    </div>
                </div>
                {/* to top */}
                <div className="cd-top">Top</div>
            </div>
            {/* #footer bottom end */}
        </footer >

    )
}
