import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ImageLoading from '../../assets/images/image-loading.jpg';
import PeerAbid from '../../assets/images/peer-abid.png';

const SingleBlog = ({ post }) => {

    const [featuredImage, setFeaturedImage] = useState("");
    const [publisher, setPublisher] = useState("");

    const [imageLoading, setImageLoading] = useState(true);



    useEffect(() => {

        document.title = `${post.title.rendered} - Peerzada Estates`;

        setImageLoading(true)

        const getFeaturedImage = axios.get(`${process.env.REACT_APP_BASE_API_URL}/media/${post.featured_media}`);

        const getAuthor = axios.get(`${process.env.REACT_APP_BASE_API_URL}/users/${post.author}`);

        Promise.all([getFeaturedImage, getAuthor])
            .then(res => {
                setImageLoading(false)
                setFeaturedImage(res[0].data.guid.rendered)
                setPublisher(res[1].data.name)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <>
            < div className="banner-text-left lernen_banner large bg-project" >
                <div className="container">
                    <div className="row">
                        <div className="lernen_banner_title">
                            <h1>
                                {post.title.rendered}
                            </h1>
                            <div className="lernen_breadcrumb">
                                <div className="breadcrumbs">
                                    <span className="first-item">
                                        <Link to="/">Home</Link>
                                    </span>
                                    <span className="separator">&gt;</span>
                                    <span className="last-item">Read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div id="blog-detail" className="wrap-bg wrap-bg ">
                <div className="container">
                    <div className="row">
                        {/* Content Left */}
                        <div className="col-md-8">
                            <div className="blog-content">
                                <div className="blog-detail-img">
                                    <div className="projectslider owl-carousel owl-theme owl-loaded owl-drag">
                                        <div className="owl-stage-outer">
                                            <div className="item">
                                                <img src={featuredImage} title={post.title.rendered} alt={post.title.rendered} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title mt-5">
                                        <div>
                                            <h3>
                                                {post.title.rendered}
                                            </h3>
                                        </div>
                                        <div className="course-viewer">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-user" />Published by
                                                    <strong> {publisher}</strong>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                    <hr />
                                    <p dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                                </div>
                            </div>
                            {/* Sidebar Right */}
                        </div>
                        <div className="col-md-4">
                            <div id="members" className="item">{/* single teacher */}
                                <a href="https://twitter.com/abid_peerzada_" className="fancybox" data-fancybox-group="images_gallery">
                                    <img src={PeerAbid} alt="Abid Peerzada" /></a>
                                <h5>
                                    Peerzada Abid
                                </h5>
                                <p className="mb-4">
                                    Entrepreneur and Influencer <br />
                                    Managing Director at Peerzada Estates
                                </p>
                                <p>
                                    <i className="far fa-envelope-open mr-3"></i>
                                    Contact: (+91)908 600 0009
                                </p>
                                <p>
                                    <i className="far fa-envelope-open mr-3"></i>
                                    Email: peerzadaestates@gmail.com
                                </p>
                            </div>
                            <div className="single-features-light text-center">{/* single features */}
                                <div className="move">
                                    {/* uses solid style */}
                                    <i className="secondary-color fas fa-home fa-3x" />
                                    <h4>
                                        <a href="tel:9086000009">Contact</a>
                                    </h4>
                                    <p>
                                        JAK Bro Complex, Munawar Abad, Srinagar, Jammu and Kashmir, 190001.
                                    </p>
                                    <div className="feature_link">
                                        <a href="tel:+919086000009">Call Now <i className="fas fa-arrow-right" /></a>
                                    </div>
                                </div>
                            </div>{/* end single features */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleBlog;
