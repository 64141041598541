import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

export default function About() {

    useEffect(() => {
        document.title = "About - Peerzada Estates";
    }, [])

    return (
        <>
            <main>
                {/* breadcrumb banner content area start */}
                <div className="banner-text-left lernen_banner large bg-about">
                    <div className="container">
                        <div className="row">
                            <div className="lernen_banner_title">
                                <h1>About Company</h1>
                                <div className="lernen_breadcrumb">
                                    <div className="breadcrumbs">
                                        <span className="first-item">
                                            <Link to="/">Home</Link></span>
                                        <span className="separator">></span>
                                        <span className="last-item">About</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb banner content area start */}
                {/* services area start */}
                <div id="services" className="wrap-bg">
                    {/* .container */}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="section-title section-text-left">
                                    <div>
                                        <span className="bg-start-light">About Us</span>
                                        <h3>Introduction to Peerzada Estates</h3>
                                        <p>
                                            Established in the year 2011, Peerzada Estates is among the pioneers of real estate brokerage firms in India and Dubai, delivering comprehensive market-leading real estate products and services to its clientele. With our success built on keeping our valuable clients our top priority, we are committed to delivering the best brokerage service in purchasing, leasing, selling and management of properties in the industry.
                                        </p>
                                        <p>
                                            With over 8 years of operations, Peerzada Estates has served numerous customers both local and international, built strong ties with trusted developers and property management companies in the market, ensuring our clients with a variety of superb properties to choose from and allowing us to help them achieve their commercial or residential goals by seamlessly integrating knowledge and information that are in sync with their challenges.
                                        </p>
                                        <ul className="themeioan_ul_icon">
                                            <li>
                                                <i className="fas fa-check-circle" />
                                                Best Service
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" />
                                                Bank Finance
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" />
                                                Trusted by thousands
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" />
                                                Experienced Agency
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" />
                                                Huge Connections
                                            </li>
                                        </ul>
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="images\content\landing\about.jpg" alt="Buy this Course" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* services area end */}
                {/* Feature Contact */}
                <div id="feature_contact">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row feature_contact_item fc_bg1 image-overly-dark-opacity">
                                <div className="opac">
                                    <h3>Srinagar, J&K</h3>
                                    <p>
                                        JAK Bro Complex, Munawar Abad, Srinagar, Jammu and Kashmir 190001
                                        <br />
                                        Email: peerzadaestates@gmail.com
                                        <br />
                                        Phone: (+91) 908 600 0009
                                    </p>
                                    <a href="tel:919086000009" className="color-one btn-custom">
                                        Get in touch <i className="fas fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row feature_contact_item fc_bg2 image-overly-dark-opacity">
                                <div className="opac">
                                    <h3 className="mb-3">Thought of the day</h3>
                                    <h4 className="text-white mb-5">
                                        Buying real estate is not only the best way, the quickest way, the safest way, but the only way to become wealthy.
                                    </h4>
                                    <h4 className="text-white mb-3">
                                        Pick up your phone and give us a call.
                                    </h4>
                                    <a href="tel:919086000009" className="color-one btn-custom">
                                        Get in touch <i className="fas fa-arrow-right" />
                                    </a>
                                    <a href="tel:919086000009" className="ml-4 color-one btn-custom">
                                        Lets Invest <i className="fas fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Feature Contact end */}
            </main>
        </>
    )
}
