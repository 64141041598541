import React, { useState, useEffect } from 'react'

import Header from './components/inc/Header'
import Footer from './components/inc/Footer'
import Home from './components/pages/Home'
import About from './components/pages/About'
import Deals from './components/pages/Deals'
import Deal from './components/pages/Deal'
import Services from './components/pages/Services';
import Blog from './components/pages/Blog';
import Contact from './components/pages/Contact';

import PageNotFound from './components/pages/PageNotFound';
import RestoreScroll from './components/inc/RestoreScroll';

import { Route, Switch, Redirect } from 'react-router-dom'
import Read from './components/inc/Read'

export default function App() {
    return (
        <>
            <Header />
            <RestoreScroll />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/home" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/deals" component={Deals} />
                <Route path="/deal/:slug" component={Deal} />
                <Route path="/services" component={Services} />
                <Route path="/blog" component={Blog} />
                <Route path="/read/:slug" component={Read} />
                <Route path="/contact" component={Contact} />
                <Route exact path="/secure">
                    <Redirect to="/api/login" />
                </Route>
                <Route component={PageNotFound} />
            </Switch>
            <Footer />
        </>
    )
}