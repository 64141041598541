import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingImage from '../../assets/images/image-loading.jpg';


const DealItem = ({ deal }) => {

    const [image, setImage] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_API_URL}/media/${deal.featured_media}`;
        axios.get(url)
            .then((res) => {
                setLoading(false)
                setImage(res.data.guid.rendered)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    return (
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 course-single mb20">
            <div className="themeioan_services">
                <article>
                    <div className="blog-photo">
                        <a href="project-detail.html">
                            {loading ? <img src={LoadingImage} alt="Peer" /> : <img src={image} alt="Peer" />}
                        </a>
                    </div>
                    <div className="blog-content absolute-transparent">
                        <h5 className="title">
                            <a href="project-detail.html">
                                {deal.title.rendered}
                            </a>
                        </h5>
                        <p dangerouslySetInnerHTML={{ __html: deal.excerpt.rendered }} />
                        <Link to={`/deal/${deal.slug}`} className="color-two btn-custom btn-sm">
                            Learn more
                        </Link>
                    </div>
                </article>
            </div>
        </div>
    );
}

export default DealItem;