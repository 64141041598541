import React, { useState, useEffect } from 'react';
import axios from 'axios';

import DealItem from '../inc/DealItem';
import GridLoader from '../loaders/GridPostLoader';

const DealsGrid = ({ perPage }) => {
    const [loading, setLoading] = useState(false)
    const [deals, setDeals] = useState([])

    useEffect(() => {

        document.title = "Deals & Projects - Peerzada Estates";
        setLoading(true)
        const url = `${process.env.REACT_APP_BASE_API_URL}/deals?per_page=${perPage}`;

        axios.get(url)
            .then((res) => {
                setLoading(false)
                setDeals(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    return (
        <div id="projects" className="wrap-bg wrap-bg-dark">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-8">
                        <div className="section-title with-p">
                            <span className="bg-start-light">New Deals</span>
                            <h2>Latest Projects</h2>
                            <p>
                                Choose from the wide range of projects and deals available at
                                Peerzada Estates
                                 </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {loading ? <GridLoader /> : deals.map(deal => (
                        <DealItem deal={deal} key={deal.id} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DealsGrid;
