import React from 'react';
import { Link } from 'react-router-dom';

const ServicesGrid = () => {
    return (
        <div id="services_image" className="wrap-bg">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-8">
                        <div className="section-title with-p">
                            <span className="bg-start-light">Services</span>
                            <h2>
                                We Offer Best Services &amp; Solutions
                                </h2>
                            <p>
                                Let's guide you through our range of services in the Real Estate Industry. We can help you make positive desicions.
                                </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="services_image services_bg1 image-overly-dark-opacity">
                            <div className="opac">
                                <h3>Flats & Apartments Booking</h3>
                                <p>
                                    Peerzada Estates guarantees best service through out Kashmir, India & Dubai. We are the best among the rest. Lets start.
                                    </p>
                                <Link to="/contact" className="color-two btn-custom">
                                    Get in touch <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="services_image services_bg2 image-overly-dark-opacity">
                            <div className="opac">
                                <h3>Land & House Investments</h3>
                                <p>
                                    Peerzada Estates guarantees best service through out Kashmir, India & Dubai. We are the best among the rest. Lets start.
                                    </p>
                                <Link to="/contact" className="color-two btn-custom">
                                    Get in touch <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="services_image services_bg3 image-overly-dark-opacity">
                            <div className="opac">
                                <h3>Real Estate Consulting</h3>
                                <p>
                                    Peerzada Estates guarantees best service through out Kashmir, India & Dubai. We are the best among the rest. Lets start.
                                    </p>
                                <Link to="/contact" className="color-two btn-custom">
                                    Get in touch <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="services_image services_bg4 image-overly-dark-opacity">
                            <div className="opac">
                                <h3>Construction & Renovation</h3>
                                <p>
                                    Peerzada Estates guarantees best service through out Kashmir, India & Dubai. We are the best among the rest. Lets start.
                                    </p>
                                <Link to="/contact" className="color-two btn-custom">
                                    Get in touch <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="services_image services_bg5 image-overly-dark-opacity">
                            <div className="opac">
                                <h3>Home Design & Interior</h3>
                                <p>
                                    Peerzada Estates guarantees best service through out Kashmir, India & Dubai. We are the best among the rest. Lets start.
                                    </p>
                                <Link to="/contact" className="color-two btn-custom">
                                    Get in touch <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="services_image services_bg6 image-overly-dark-opacity">
                            <div className="opac">
                                <h3>House & Office Renovation</h3>
                                <p>
                                    Peerzada Estates guarantees best service through out Kashmir, India & Dubai. We are the best among the rest. Lets start.
                                    </p>
                                <Link to="/contact" className="color-two btn-custom">
                                    Get in touch <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesGrid;
