import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingImage from '../../assets/images/image-loading.jpg';

const BlogItem = ({ post }) => {

    const [featuredImage, setFeaturedImage] = useState("");
    const [author, setAuthor] = useState("");
    const [loadingImage, setLoadingImage] = useState(true);
    // Get artist name and featured image

    useEffect(() => {
        setLoadingImage(true)
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/media/${post.featured_media}`)
            .then((res) => {
                setFeaturedImage(res.data.guid.rendered)
                setLoadingImage(false)
            })
            .catch(err => console.log(err))

        axios.get(`${process.env.REACT_APP_BASE_API_URL}/users/${post.author}`)
            .then((res) => {
                setAuthor(res.data.name);
            })
            .catch(err => console.log(err))

    }, [])

    return (
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 blog-single">
            {/* 1 */}
            <div className="themeioan_blog">
                <article>{/* single blog articles */}
                    <div className="blog-photo">
                        <Link to={`/read/${post.slug}`}>
                            {loadingImage ? <img src={LoadingImage} alt="" /> : <img src={featuredImage} alt="Blog" />}
                        </Link>
                    </div>
                    <div className="blog-content">
                        <div className="course-viewer">
                            <ul>
                                <li><i className="fas fa-user" /> {author}</li>
                            </ul>
                        </div>
                        <h5 className="title">
                            <Link to={`/read/${post.slug}`}>
                                {post.title.rendered}
                            </Link>
                        </h5>
                        <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}>
                        </p>
                        <Link to={`/read/${post.slug}`} className="readmore">
                            Read More
                            <i className="fas fa-arrow-right" />
                        </Link>
                    </div>
                </article>
                {/* end single blog articles */}
            </div>
        </div>
    );
}

export default BlogItem;
