import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

export default function Contact() {

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };

    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        form.submitButton.innerHTML = "Please wait...";
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://formspree.io/xjveqayn",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Message sent successfully!", form);
                form.submitButton.innerHTML = "Request a Call Back";
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };



    useEffect(() => {
        document.title = "Contact Us - Peerzada Estates";
    })

    return (
        <main>
            <div className="image-overly-primary lernen_banner bg-contact2">
                <div className="container">
                    <div className="row">
                        <div className="lernen_banner_title">
                            <h1>We’re Here To Help You</h1>
                            <div className="lernen_breadcrumb">
                                <div className="breadcrumbs">
                                    <span className="first-item">
                                        <Link to="/">Home</Link>
                                    </span>
                                    <span className="separator">&gt;</span>
                                    <span className="last-item">
                                        Contact
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Contact Details */}
            <div id="features" className="wrap-bg wrap-bg-dark">
                {/* .container */}
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-4">
                            {/* 1 */}
                            <div className="single-features-light text-center">{/* single features */}
                                <div className="move">
                                    {/* uses solid style */}
                                    <i className="secondary-color fas fa-home fa-3x" />
                                    <h4>
                                        Srinagar Office
                                    </h4>
                                    <p>
                                        JAK Bro Building, Munawar Abad, Srinagar, J&K 190002
                                    </p>
                                    <div className="feature_link">
                                        <a href="https://goo.gl/maps/t2HSkKx6cUrg3EfG8" target="blank">
                                            View Directions
                                            <i className="fas fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>{/* end single features */}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4">
                            {/* 2 */}
                            <div className="single-features-light text-center">{/* single features */}
                                <div className="move">
                                    <i className="secondary-color fas fa-phone fa-3x" />
                                    <h4>
                                        Contact Number
                                    </h4>
                                    <p>
                                        (+91) 908 600 0009
                                    </p>
                                    <div className="feature_link">
                                        <a href="tel:+919086000009">Call Now
                                        <i className="fas fa-arrow-right" /></a>
                                    </div>
                                </div>
                            </div>{/* end single features */}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 ">
                            {/* 3 */}
                            <div className="single-features-light text-center">{/* single features */}
                                <div className="move">
                                    <i className="secondary-color fas fa-envelope-open fa-3x" />
                                    <h4>
                                        Email
                                    </h4>
                                    <p>
                                        peerzadaestates@gmail.com
                                    </p>
                                    <div className="feature_link">
                                        <a href="mailto:peerzadaestates@gmail.com">
                                            Send Email
                                        <i className="fas fa-arrow-right" /></a>
                                    </div>
                                </div>
                            </div>{/* end single features */}
                        </div>
                    </div>
                    {/* .row end */}
                </div>
                {/* .container end */}
            </div>

            {/** Contact Form */}

            <div id="contact" className="wrap-bg">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-8">
                            <div className="section-title with-p">
                                <span className="bg-start-light">Contact</span>
                                <h2>We’re Here To Help You</h2>
                                <p>
                                    We are waiting for you on our office in London or in way, contact us via the contact form below your idea. We are here to answer any question.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <form onSubmit={handleOnSubmit} className="form" id="">
                                {/* Change Placeholder and Title */}
                                <div>
                                    <input className="input-text" type="text" name="name" id="contactName" placeholder="Name" title="Your Full Name" required />
                                </div>
                                <div>
                                    <input className="input-text" type="email" name="email" id="contactEmail" placeholder="Email" title="Your Email" required />
                                </div>
                                <div>
                                    <input className="input-text" type="text" name="subject" id="contactSubject" placeholder="Subject" title="Your Subject" required />
                                </div>
                                <div>
                                    <textarea className="input-text" name="message" id="contactMessage" placeholder="Message" title="Your Message" defaultValue={""} required />
                                </div>
                                <button type="submit" name="submitButton" className="mb-4 color-two btn-custom btn float-right">
                                    Request a Call Back
                                </button>
                                {serverState.status && (
                                    <p className={!serverState.status.ok ? "alert alert-danger" : ""}>
                                        {serverState.status.msg}
                                    </p>
                                )}
                            </form>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <iframe title="Google Map Directions"
                                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d105743.11457544281!2d74.7491985756154!3d34.08303853064783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e3!4m0!4m5!1s0x38e18f2254b94661%3A0x5ffa13805c62b1ce!2sPeerzada%20Estates%2C%20Srinagar!3m2!1d34.0830584!2d74.8192387!5e0!3m2!1sen!2sin!4v1587294885960!5m2!1sen!2sin"
                                width={600}
                                height={370}
                                frameBorder={0}
                                style={{ border: 0 }}
                                allowFullScreen
                                aria-hidden="false"
                                tabIndex={0}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
