import React, { useState, useEffect } from 'react';
import BlogItem from '../inc/BlogItem';
import axios from 'axios';
import GridPostLoader from '../loaders/GridPostLoader';

const BlogGrid = ({ perPage }) => {

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // Fetch posts
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/posts?per_page=${perPage}`)
            .then(res => {
                setLoading(true)
                setPosts(res.data)
                setLoading(false)
            })
            .catch(err => console.log(err))
    }, [])


    return (
        <div id="blog" className="wrap-bg wrap-bg-dark ">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-8">
                        <div className="section-title with-p">
                            <span className="bg-start-light"> Blog Article</span>
                            <h2>Latest Blog Posts</h2>
                            <p>
                                Check out our latest blog posts, news and articles. We have articles on a range of topics. You will love reading.
                            </p>
                        </div>
                    </div>
                </div>
                {/* .row */}
                <div className="row">
                    {loading ? <GridPostLoader /> : (posts.map((post) => {
                        return <BlogItem post={post} key={post.id} />
                    }))}
                </div>
                {/* .row end */}
            </div>
        </div>

    );
}

export default BlogGrid;
