import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SingleBlog from '../inc/SingleBlog';

const Read = (props) => {

    const slug = props.match.params.slug;

    const [post, setPost] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/posts?slug=${slug}`)
            .then(res => {
                setPost(res.data[0])
                setLoading(false)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <main>
            {!loading ? <SingleBlog post={post} /> : ""}
        </main>
    );
}

export default Read;
