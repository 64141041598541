import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import DealsGrid from '../inc/DealsGrid';


const Deals = () => {

    useEffect(() => {
        document.title = "Deals - Peerzada Estates";
    }, [])

    return (

        < main >
            {/* breadcrumb banner content area start */}
            < div className="banner-text-left lernen_banner large bg-project" >
                <div className="container">
                    <div className="row">
                        <div className="lernen_banner_title">
                            <h1>
                                Latest Deals and Projects
                            </h1>
                            <div className="lernen_breadcrumb">
                                <div className="breadcrumbs">
                                    <span className="first-item">
                                        <Link to="/">Home</Link>
                                    </span>
                                    <span className="separator">&gt;</span>
                                    <span className="last-item">Deals</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* end breadcrumb banner content area start */}
            {/* projects area start */}
            <DealsGrid perPage={100} />
        </main >
    )
}

export default Deals;