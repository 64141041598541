import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SingleDeal from '../inc/SingleDeal';

const Deal = (props) => {

    const slug = props.match.params.slug;

    const [deal, setDeal] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        document.title = "Latest Deal";

        const url = `${process.env.REACT_APP_BASE_API_URL}/deals?slug=${slug}`;
        setLoading(true);
        axios.get(url)
            .then(res => {
                setDeal(res.data[0]);
                setLoading(false)
            })
            .catch(err => console.log(err))
    }, [])


    return (
        <>
            {loading ? <h1>Loading...</h1> : <SingleDeal deal={deal} />}
        </>
    );
}

export default Deal;
