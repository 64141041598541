import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function RestoreScroll() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.querySelector('.navbar-collapse').style.display = "none";
        document.querySelector('.burger-icon').className = "burger-icon";
    }, [pathname]);

    return null;
}