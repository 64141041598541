import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import BlogGrid from '../inc/BlogGrid';

const Blog = () => {

    useEffect(() => {
        document.title = "Blog - Peerzada Estates";
    }, [])

    return (
        <main>
            {/* breadcrumb banner content area start */}
            <div className="banner-text-left lernen_banner large bg-about">
                <div className="container">
                    <div className="row">
                        <div className="lernen_banner_title">
                            <h1>Blog</h1>
                            <div className="lernen_breadcrumb">
                                <div className="breadcrumbs">
                                    <span className="first-item">
                                        <Link to="/">Home</Link>
                                    </span>
                                    <span className="separator">></span>
                                    <span className="last-item">Blog</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/** Blog Start */}
            <BlogGrid perPage={100} />
            {/** Blog End */}
        </main>
    );
}

export default Blog;
