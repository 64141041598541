import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import ServicesGrid from '../inc/ServicesGrid';
import DealsGrid from '../inc/DealsGrid';
import BlogGrid from '../inc/BlogGrid';

export default function Home() {

    useEffect(() => {
        document.title = 'Home - Peerzada Estates';
    });

    return (
        <main>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-12">
                        <div className="item">
                            <div className="position-center header-content bg-home-ome image-overly-dark-opacity" role="banner">
                                <div className="container opac">
                                    <div className="col-xs-12 col-sm-12 header-area">
                                        <div className="header-area-inner header-text">
                                            {/* single content header */}
                                            <div>
                                                <span className="subtitle ">
                                                    We are the best among the rest.
                                                </span>
                                            </div>
                                            <p>
                                                Best of Real Estate deals from Jammu and Kashmir, Parts of India and Dubai, UAE. Lets guide you to the best.
                                            </p>
                                            <div className="btn-section">
                                                <Link to="/deals" className="color-two btn-custom">
                                                    View Projects <i className="fas fa-arrow-right" />
                                                </Link>
                                                <div className="video-relative">
                                                    <a target="blank" href={"https://www.youtube.com/watch?v=29n3M6MIjqc"} className="video-btn popup-video">
                                                        <i className="fas fa-play" />
                                                        <span className="ripple orangebg" />
                                                        <span className="ripple orangebg" />
                                                        <span className="ripple orangebg" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Services Component */}
            <ServicesGrid />

            {/** Projects Start */}
            <DealsGrid perPage={3} />
            {/** Projects End */}

            <BlogGrid perPage={3} />


        </main>

    )
}
