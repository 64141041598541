import React from 'react';
import PostLoader from '../../assets/images/post-loader.png';

const GridPostLoader = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <img src={PostLoader} alt="Loading" />
                </div>
                <div className="col-md-4">
                    <img src={PostLoader} alt="Loading" />
                </div>
                <div className="col-md-4">
                    <img src={PostLoader} alt="Loading" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <img src={PostLoader} alt="Loading" />
                </div>
                <div className="col-md-4">
                    <img src={PostLoader} alt="Loading" />
                </div>
                <div className="col-md-4">
                    <img src={PostLoader} alt="Loading" />
                </div>
            </div>
        </div>
    );
}

export default GridPostLoader;
