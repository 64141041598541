import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const PageNotFound = () => {

    useEffect(() => {
        document.title = "404! - Peerzada Estates";
    }, [])

    return (
        <main>
            {/* breadcrumb banner content area start */}
            <div className="banner-text-left lernen_banner ">
                <div className="container">
                    <div className="row">
                        <div className="lernen_banner_title text-center mx-auto">
                            <h1 className="text-center mx-auto">Ooops! The page you are looking for does not exist.</h1>
                            <div className="lernen_breadcrumb">
                                <div className="breadcrumbs">
                                    <Link to="/" className="btn btn-1c mt-5">Go back Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default PageNotFound;
