import React from 'react'
import Logo from '../../logo.svg'
import { NavLink, Link } from 'react-router-dom'

const Header = () => {

    return (
        <header id="header" className="transparent-header">
            <div className="topheader top_header_light hidemobile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="address-icon"><i className="fas fa-headphones" />
                                <a href="tel:919086000009">+91 908 600 0009</a>
                            </div>
                            <div className="address-icon">
                                <i className="far fa-envelope-open" />
                                <a href="https://goo.gl/maps/t2HSkKx6cUrg3EfG8">
                                    JAK Bro Complex, Munawar Abad, Srinagar
                                </a>
                            </div>
                            <div className="address-icon">
                                <i className="fas fa-home" />
                                <a href="mailto:peerzadaestates@gmail.com">peerzadaestates@gmail.com</a>
                            </div>
                        </div>
                        <div className="col-lg-5 text-right">
                            <div className="custom-page-top">
                                <a href="https://emicalculator.net" target="blank">EMI Calculator</a>
                                <Link to="/about">Privacy Policy</Link>
                            </div>
                            <div className="social_top_header">
                                <a href="http://facebook.com/peerzadaestates" target="blank">
                                    <i className="fab fa-facebook" aria-hidden="true" />
                                </a>
                                <a href="http://twitter.com/peerzadaestates" target="blank">
                                    <i className="fab fa-twitter" aria-hidden="true" />
                                </a>
                                <a href="http://instagram.com/peerzadaestates" target="blank">
                                    <i className="fab fa-instagram" aria-hidden="true" />
                                </a>
                                <a href="https://www.youtube.com/channel/UCDGdwYtoKYxgmkB1qbhGQBQ" target="blank">
                                    <i className="fab fa-youtube" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* #navigation start */}
            <nav className="navbar navbar-default navbar-expand-md navbar-light" id="navigation" data-offset-top={1}>
                {/* .container */}
                <div className="container">
                    {/* Logo and Menu */}
                    <div className="navbar-header">
                        <div className="navbar-brand">
                            <Link to="/">
                                <img src={Logo} alt="Logo" style={{ height: 60 }} />
                            </Link>
                        </div>
                        {/* site logo */}
                    </div>
                    {/* Menu Toogle */}
                    <div className="burger-icon">
                        <div className="bar1" />
                        <div className="bar2" />
                        <div className="bar3" />
                    </div>
                    <div className="collapse navbar-collapse " id="navbarCollapse">
                        <ul className="nav navbar-nav ml-auto">
                            {/* Menu Link */}
                            <li>
                                <NavLink to="/" exact activeClassName="active">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" activeClassName="active">About</NavLink>
                            </li>
                            <li>
                                <NavLink to="/deals" activeClassName="active">Latest Deals</NavLink>
                            </li>
                            <li>
                                <NavLink to="/services" activeClassName="active">Services</NavLink>
                            </li>
                            <li>
                                <NavLink to="/blog" activeClassName="active">Blog</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" activeClassName="active">Contact</NavLink>
                            </li>
                        </ul>
                        <div className="header-cta">
                            <a href="tel:+919086000009" className="btn btn-1c">
                                <i className="fa fa-phone mr-1"></i>
                                Call Now
                            </a>
                        </div>
                    </div>
                    {/* Menu Toogle end */}
                </div>
                {/* .container end */}
            </nav>
            {/* #navigation end */}
        </header>

    )
}

export default Header;
