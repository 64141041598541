import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingImage from '../../assets/images/image-loading.jpg';
import AbidAvatar from '../../assets/images/peer-abid.png';

const SingleDeal = ({ deal }) => {

    const [featuredImage, setFeaturedImage] = useState("");
    const [loadingImage, setLoadingImage] = useState(true);

    useEffect(() => {

        document.title = `${deal.title.rendered} - Peerzada Estates`;

        const url = `${process.env.REACT_APP_BASE_API_URL}/media/${deal.featured_media}`;
        setLoadingImage(true)
        axios.get(url)
            .then(res => {
                setFeaturedImage(res.data.guid.rendered)
                setLoadingImage(false)
            })
            .catch(err => console.log(err))
    }, [])


    return (
        <main>
            < div className="banner-text-left lernen_banner large bg-project" >
                <div className="container">
                    <div className="row">
                        <div className="lernen_banner_title">
                            <h1>
                                {deal.title.rendered}
                            </h1>
                            <div className="lernen_breadcrumb">
                                <div className="breadcrumbs">
                                    <span className="first-item">
                                        <Link to="/">Home</Link>
                                    </span>
                                    <span className="separator">&gt;</span>
                                    <span className="last-item">Deals</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div id="blog-detail" className="wrap-bg wrap-bg ">
                <div className="container">
                    <div className="row">
                        {/* Content Left */}
                        <div className="col-md-8">
                            <div className="blog-content">
                                <div className="blog-detail-img">
                                    <div className="projectslider owl-carousel owl-theme owl-loaded owl-drag">
                                        <div className="owl-stage-outer">
                                            <div className="item">
                                                {loadingImage ? <img src={LoadingImage} className="rounded img-fluid" alt="" /> : <img src={featuredImage} className="rounded" alt="" />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title mt-5">
                                        <div>
                                            <h3>
                                                {deal.title.rendered}
                                            </h3>
                                        </div>
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: deal.excerpt.rendered }} />
                                    <hr />
                                    <p dangerouslySetInnerHTML={{ __html: deal.content.rendered }} />
                                </div>
                            </div>
                            {/* Sidebar Right */}
                        </div>
                        <div className="col-md-4">
                            <div id="members" className="item">{/* single teacher */}
                                <a href="https://twitter.com/abid_peerzada_" className="fancybox" data-fancybox-group="images_gallery">
                                    <img src={AbidAvatar} alt="Abid Peerzada" /></a>
                                <h5>
                                    Peerzada Abid
                                </h5>
                                <p className="mb-4">
                                    Entrepreneur and Influencer <br />
                                    Managing Director at Peerzada Estates
                                </p>
                                <p>
                                    <i className="far fa-envelope-open mr-3"></i>
                                    Contact: (+91)908 600 0009
                                </p>
                                <p>
                                    <i className="far fa-envelope-open mr-3"></i>
                                    Email: peerzadaestates@gmail.com
                                </p>
                            </div>
                            <div className="single-features-light text-center">{/* single features */}
                                <div className="move">
                                    {/* uses solid style */}
                                    <i className="secondary-color fas fa-home fa-3x" />
                                    <h4>
                                        <a href="tel:9086000009">Contact</a>
                                    </h4>
                                    <p>
                                        JAK Bro Complex, Munawar Abad, Srinagar, Jammu and Kashmir, 190001.
                                    </p>
                                    <div className="feature_link">
                                        <a href="tel:+919086000009">Call Now <i className="fas fa-arrow-right" /></a>
                                    </div>
                                </div>
                            </div>{/* end single features */}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default SingleDeal;
